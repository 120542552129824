/**
 *
 */
// import { useSubscriptionStore } from '@/stores/SubscriptionStore'
// import { storeToRefs } from 'pinia'

// const subscription = useSubscriptionStore()
// const { price } = storeToRefs(useSubscriptionStore())

export default {
  methods: {

    // Get ix using object { project: {}, form: {} }
    getIx (prop) {
      const projects = this.$store.state.bend.organization.projects
      // find and set index of selected project and form
      const pindex = projects.findIndex(p => p.id === prop.project.id)
      let findex
      if ('form' in prop) {
        findex = projects[pindex].forms.findIndex(f => f.id === prop.form.id)
      } else {
        findex = null
      }

      // commit
      const ix = { pindex: pindex, findex: findex }
      return ix
    },

    // Go back to the previous page
    goBack () {
      this.$router.go(-1)
    },

  },
}
