/**
 * getProjects() -  Get projects from bend and prepare all details of Project forms and Project roles
 * postProject() - Generate a new PROJECT
 * deleteProject() - Delete project node from Organization node in bend
 *
 */

export default {
  methods: {
    /**
   * Get projects from bend and prepare all details of Project forms and Project roles
   * Variables:
   *  params <= url params for dowloading only needed information
   * Sub Fuctions:
   *  prepProject(data) <= (Step 3.1) Collects project with all details, called after get api call
   *  getforms(data, forms) <= (Step 3.2) collects project forms with all details, called from prepProject()
   *  getroles(data, roles, users) <= (Step 3.3) collects user roles. called from prepProject()
   * Step 1. Prepare url params to be included into json get url
   *  1.2 Get Organization ID from store
   *  1.3 Prepare urlparams from object
   * Step 2 Send Get request to bend and get project with details included into param variable
   * Step 3 Prepare project with all details to include to store
   *  3.1 Collect project with all details
   *  3.2 Collect forms of the project with all details
   *  3.3 Collect roles of project with user details
   */
    async getProjects () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      // console.log('getProjects da')
      // STEP 3.1. sub function to collect project with all details, called after get api call
      const prepProject = (data) => {
        // console.log('prepProject da')
        const fullprj = []
        // Extract nodes by filtering
        const projects = data.filter(function (obj) { return (obj.type === 'node--project') })
        const forms = data.filter(function (obj) { return (obj.type === 'node--form') })
        const roles = data.filter(function (obj) { return (obj.type === 'node--roles') })
        const users = data.filter(function (obj) { return (obj.type === 'user--user') })
        // console.log(projects)

        projects.forEach(el => {
          const pdetails = {}
          pdetails.id = el.id
          pdetails.changed = el.attributes.changed
          pdetails.created = el.attributes.created
          pdetails.prjid = el.attributes.field_folderid
          pdetails.nid = el.attributes.drupal_internal__nid
          pdetails.archived = el.attributes.field_archived
          pdetails.pid = el.attributes.field_pid
          pdetails.title = el.attributes.title
          // pdetails.odk_server = el.attributes.field_url.uri
          // get roles
          pdetails.roles = getroles(el.relationships.field_roles.data, roles, users)
          pdetails.myrole = 'none'
          pdetails.roles.forEach(u => {
            // If active user has other than 'none' role assign it
            if (u.id === this.$store.state.auth.user.uuid) {
              pdetails.myrole = u.role
            }
          })
          // get forms
          pdetails.forms = getforms(el.relationships.field_odkform.data, forms)
          fullprj.push(pdetails)
        })
        // console.log(fullprj)
        // Include ix values; project title into each form. Used in activeForms() (FormsActive.vue)
        fullprj.forEach(p => {
          if ('forms' in p) {
            p.forms.forEach(f => {
              f.ix = {}
              f.ix.pindex = fullprj.indexOf(p)
              f.ix.findex = p.forms.indexOf(f)
              f.project = p.title
            })
          }
        })
        return fullprj
      }

      // STEP 3.2 sub function to collect project forms with all details, called from prepProject()
      const getforms = (data, forms) => {
        const myform = []
        const eachform = (id) => {
          const formdata = forms.filter(function (obj) { return (obj.id === id) })
          const formitem = {}
          formitem.id = formdata[0].id
          formitem.changed = formdata[0].attributes.changed
          formitem.created = formdata[0].attributes.created
          // formitem.form_id = formdata[0].attributes.field_form_id
          formitem.body = formdata[0].attributes.body.value
          formitem.odksettings = JSON.parse(formdata[0].attributes.field_form_settings)
          formitem.title = formitem.odksettings.title
          const odk = JSON.parse(formdata[0].attributes.field_odk)
          formitem.odkquestions = odk.odkquestions
          formitem.odkmodels = odk.odkmodels
          formitem.odklangs = odk.odklangs
          formitem.startDate = formdata[0].attributes.field_dates.value
          formitem.syncDate = formdata[0].attributes.field_date
          formitem.submissions = formdata[0].attributes.field_submissions
          myform.push(formitem)
        }
        data.forEach(el => { eachform(el.id) })
        // console.log(myform)
        return myform
      }

      // STEP 3.3 sub function to collect user roles. called from prepProject()
      const getroles = (data, roles, users) => {
        // console.log('getroles')
        // get user roles
        const myrole = []
        const eachrole = (id) => {
          const roledata = roles.filter(function (obj) { return (obj.id === id) })
          // console.log(roledata)
          const userid = roledata[0].relationships.field_user.data.id
          const userdata = users.filter(function (obj) { return (obj.id === userid) })
          // console.log(userdata)
          const useritem = {}
          useritem.roleid = roledata[0].id
          useritem.role = roledata[0].attributes.field_project_role
          useritem.id = userdata[0].id
          useritem.access = userdata[0].attributes.access
          useritem.changed = userdata[0].attributes.changed
          useritem.created = userdata[0].attributes.created
          useritem.name = userdata[0].attributes.name
          useritem.mail = userdata[0].attributes.mail
          myrole.push(useritem)
        }
        data.forEach(el => { eachrole(el.id) })
        // console.log(myrole)
        return myrole
      }

      // STEP 1. Prepare params to be included into json get url
      // STEP 1.2 Get Organization ID from store
      const orgid = this.$store.state.bend.organization.id
      // STEP 1.3 Prepare urlparams from object
      const params = {
        include: 'field_projects,field_projects.field_odkform,field_projects.field_roles,field_projects.field_roles.field_user',
        'fields[node--organization]': 'title',
        'fields[node--project]': 'id,drupal_internal__nid,title,created,changed,field_archived,field_pid,field_odkform,field_roles,field_folderid,field_url',
        'fields[node--form]': 'id,title,created,changed,field_form_id,body,field_odk,field_form_settings,field_date,field_submissions,field_dates',
        'fields[node--roles]': 'id,field_project_role,field_user',
        'fields[user--user]': 'id,name,access,created,changed,mail',
      }
      // Join each object's key and value as string and push into myarray
      const myarray = []
      for (const [key, value] of Object.entries(params)) {
        myarray.push(`${key}=${value}`)
      }
      // Join into one string
      const urlparam = myarray.join('&')

      // STEP 2. Send Get request to bend and get project with details included into param variable
      // console.log(orgid)
      return new Promise((resolve, reject) => {
        this.$httpBend
          .get('jsonapi/node/organization/' + orgid + '?' + urlparam, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(response => {
            // console.log(response.data)
            // STEP 3 Prepare project with all details to include to store
            // if included in in the response data run code prepProject otherwise write empty array
            const myproject = ('included' in response.data) ? prepProject(response.data.included) : []
            // console.log(myproject)
            this.$store.commit('bend/setOrgProjects', myproject)
            this.$store.commit('bend/setCalls', { projects: true })
            this.projects = myproject
            return resolve(myproject.length > 0)
          })
          .catch(() => {
            return resolve(false)
          })
      })

      /*
      Example of API get Response
      const apigetresponse = {
        // first - organization part
        data: {
            type: 'node--organization',
            id: 'ea50a9c1-c203-4157-84d7-0dc842a81fd6',
            attributes: {
                title: 'Firm Kurbanov',
            },
        },
        included: [
            // project part
            {
                type: 'node--project',
                id: '243468be-889f-452a-93f8-6f7910a4f78e',
                attributes: {
                    drupal_internal__nid: 1734,
                    title: 'Functioning last',
                    created: '2020-05-28T08:43:02+00:00',
                    changed: '2020-05-28T09:48:41+00:00',
                    field_archived: false,
                    field_pid: 20,
                },
                relationships: {
                    field_odkform: {
                        data: [
                            {
                                type: 'node--form',
                                id: 'a994ca68-39b5-41ec-8cd0-229841a02d23',
                            },
                        ],
                    },
                    field_roles: {
                        data: [
                            {
                                type: 'node--roles',
                                id: '20850ac6-284c-4bfb-90e7-f2be12274429',
                            },
                            {
                                type: 'node--roles',
                                id: '6cbddb91-a1fe-4c75-a441-347f23807411',
                            },
                        ],
                    },
                },
            },
            // forms part
            {
                type: 'node--form',
                id: 'a994ca68-39b5-41ec-8cd0-229841a02d23',
                attributes: {
                    title: 'test Form',
                    created: '2020-05-28T09:46:59+00:00',
                    changed: '2020-05-28T09:47:54+00:00',
                    field_form_id: 'testform_id',
                },
            },
            // Roles part
            {
                type: 'node--roles',
                id: '20850ac6-284c-4bfb-90e7-f2be12274429',
                attributes: {
                    field_project_role: 'manager',
                },
                relationships: {
                    field_user: {
                        data: {
                            type: 'user--user',
                            id: '03c8ad47-d9eb-499e-b09f-37840fe8f694',
                        },
                    },
                },
            },
            // User details for for roles
            {
                type: 'user--user',
                id: '03c8ad47-d9eb-499e-b09f-37840fe8f694',
                attributes: {
                    name: 'Kurbanov',
                    created: '2020-05-20T09:21:24+00:00',
                    changed: '2020-05-26T12:15:44+00:00',
                    access: '2020-05-29T07:15:34+00:00',
                },
            },
        ],
      }
      */
    },

    /**
   * Generate a new PROJECT. Logic:
   * Project number should depend on subscription.
   * 1. Dispatch OCPU function ocpostproject() to generate a project in ODK Central Server (also adds project's folder)
   * 2. Post a new project to bend using name and id from ocpu response
   *  2.2 Temporarily save new project under organization (bend/setOrgNewProject)
   *  2.3 Set call.projects state to true (bend/setCalls)
   * 3. Patch organization to include new project
   *  3.2 Save updated project to organization (bend/setOrgProjects)
   */
    async postProject (projectName) {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      // console.log(projectName)
      // variable to store newly created project's json id
      let newpid = ''
      const org = this.$store.state.bend.organization
      const existingproject = ('projects' in org) ? org.projects : false
      // console.log(existingproject)
      const newproject = []

      // GET role details from bend
      const getRole = () => {
        // using current staff as default role data as only owner can do t
        const currentStaff = org.currentStaff
        return new Promise((resolve, reject) => {
          this.$httpBend
            .get(`jsonapi/node/roles/${org.defaultRole}`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              const roles = [{
                roleid: response.data.data.id,
                role: response.data.data.attributes.field_project_role,
                id: currentStaff[0].uuid,
                access: '',
                changed: currentStaff[0].changed,
                created: currentStaff[0].created,
                name: currentStaff[0].name,
              }]

              return resolve(roles)
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      const roleDetails = await getRole()

      // STEP 3. Patch Organization to add new project
      const patchOrganization = () => {
        const orgid = org.id
        const np = {
          type: 'node--project',
          id: `${newpid}`,
        }
        const body = {}
        body.data = []
        // if Organization has project we should preserve while adding a new project
        if (existingproject) {
          // console.log('existingproject if')
          // loop existing project and prepare post body
          existingproject.forEach((item, i) => {
            body.data[i] = {}
            body.data[i].type = 'node--project'
            body.data[i].id = item.id
          })
          // include new project to the beginning
          body.data.unshift(np)
        } else {
          // if organization has no project add only the new project
          body.data = [np]
        }
        // console.log(body)

        // Patch Organization and add projects
        return new Promise((resolve, reject) => {
          this.$httpBend
            .patch('jsonapi/node/organization/' + orgid + '/relationships/field_projects', body, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              if (response.status === 204) {
                // prepare Organization Projects: concat new and existing if there were any project
                const updatedprojects = !existingproject ? newproject : newproject.concat(existingproject)
                // STEP 3.2 Save updated project to organization
                this.$store.commit('bend/setOrgProjects', updatedprojects)
                this.projects = org.projects
                this.isProject = true
              } else {
                // return resolve(false)
              }
              // console.log(response)
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      // Prepare payload to be dispatched
      // generate Project unique ID using current date and time in milliseconds
      const uniq = 'IDP-' + (new Date()).getTime()
      const defserver = this.$store.state.ocpu.defServer
      const odkserver = this.ownServerUse ? this.ownServerUrl.replace(/\/?$/, '') : defserver.replace(/\/?$/, '')
      const payload = {
        pname: projectName,
        server: odkserver,
        orgid: org.orgid,
        prjid: uniq,
        ownserver: this.ownServerUse,
        user: this.ownServerUser,
        pass: this.ownServerPass,
      }
      // console.log(payload)
      // STEP 1. Dispantch OCPU function ocpostproject() to generate a project in ODK Central Server
      this.$store.dispatch('ocpu/ocpostproject', payload).then(
        response => {
          // console.log(response)
          if (response.data.statusText[0] === 'Error') {
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              timeout: 15000,
              title: `${this.$t('common.error')}`,
              text: `Could not loging into ODK Central server: ${response.data.message} `,
              visible: true,
            }
          } else if (response.data.statusText[0] === 'Success') {
            // STEP 2. Post a new project to bend using name and id from ocpu response
            const resp = response.data
            const roleid = org.defaultRole
            const data = {
              data: {
                type: 'node--project',
                attributes: {
                  title: `${resp.name}`,
                  field_pid: `${resp.id}`,
                  field_folderid: `${uniq}`,
                  field_url: {
                    uri: `${odkserver}`,
                  },
                  field_archived: false,
                },
                relationships: {
                  field_roles: {
                    data: [
                      {
                        type: 'node--roles',
                        id: `${roleid}`,
                      },
                    ],
                  },
                },
              },
            }
            //
            return new Promise((resolve, reject) => {
              this.$httpBend
                .post('jsonapi/node/project', data, {
                  headers: {
                    Authorization: `Bearer ${this.$store.state.auth.token}`,
                    'Content-Type': 'application/vnd.api+json',
                    Accept: 'application/vnd.api+json',
                  },
                })
                .then(response => {
                  // console.log(response)
                  // Update current project in vuex store with new project
                  if (response.status === 201) {
                    const data = response.data.data
                    newpid = data.id
                    const obj = {}
                    obj.id = data.id
                    obj.nid = data.attributes.drupal_internal__nid
                    obj.title = data.attributes.title
                    obj.roles = roleDetails
                    obj.myrole = roleDetails[0].role
                    // get pid from ocpostproject response
                    obj.pid = resp.id
                    // get uniq folderid from above constant variable
                    obj.prjid = uniq
                    // set archived to false
                    obj.archived = false
                    newproject.push(obj)
                    // STEP 2.2 Temporarily save new project under organization
                    // this.$store.commit('bend/setOrgNewProject', newproject)
                    this.$store.commit('bend/setCalls', { projects: true })

                    // STEP 3. Patch organization to include new project
                    patchOrganization()
                  } else {
                    // return resolve(false)
                  }
                })
                .catch(error => {
                  // console.log(error)
                  return reject(error.message)
                })
            })
          }
        },
        // error => {
        // console.log(error)
        // },
      )
    },

    /**
   * 1. Delete project node from Organization node in bend
   *  1.1 Prepare left projects of the Organization
   *  1.2 Patch Organization and re-write only left projects
   * 2. Delete Project node from bend
   * 3. Commit vuex to set left projects
   * 4. Run ocdeleteproject() function in ocpu to delete project folder completely in OCPU Server
   */
    async deleteProject () {
      // console.log('deleteProject da')
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      const org = this.$store.state.bend.organization
      const organizationId = org.id

      // STEP 2. Delete Project node from bend (Run OCPU Code to delete node)
      const deleteprojectnode = () => {
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/nodedelete/json`, {
              path: `/jsonapi/node/project/${projectjsonid}`,
              orgid: org.orgid,
            })
            .then(response => {
              // console.log(response)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }

      /*
      // STEP 2. Delete Project node from bend
      const deleteprojectnode = () => {
        return new Promise((resolve, reject) => {
          this.$httpBend.delete('/jsonapi/node/project/' + projectjsonid, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
        })
      }
      */

      // STEP 3. Run ocdeleteproject() function in opencpu to delete form folder completely in OCPU Server
      const deleteprojectfolder = () => {
        // console.log(this.orgid + ' ' + this.prjid)
        return new Promise((resolve, reject) => {
          this.$httpOcpu.post(`/${ocpuPack}/R/ocdeleteproject/json`, {
            orgid: this.orgid,
            prjid: this.prjid,
          })
        })
      }

      // STEP 1. Delete project node from organization node in bend
      // STEP 1.1. Prepare left projects of the organization
      // Function to remover current project from it's organization
      const projectjsonid = org.projects[this.ix.pindex].id
      const leftProjects = org.projects.filter(p => p.id !== projectjsonid)
      // console.log(leftProjects)
      const body = {}
      body.data = []
      // if Organization has any other projects we should keep them
      if (leftProjects.length > 0) {
        // loop existing organization and prepare post body
        leftProjects.forEach((item, i) => {
          body.data[i] = {}
          body.data[i].type = 'node--project'
          body.data[i].id = item.id
        })
      }
      // console.log(body)

      // STEP 1.2 Patch Organization and re-write only left projects (Run OCPU Code to patch node)
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `jsonapi/node/organization/${organizationId}/relationships/field_projects`,
            patchBody: body,
            orgid: org.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // STEP 2. Delete project node from bend
              deleteprojectnode()

              // STEP 3. Commit vuex to set left projects
              this.$store.commit('bend/setOrganizationProjects', { projects: leftProjects })

              // STEP 4. Delete project folder completely in OCPU Server
              deleteprojectfolder()
              this.projects = org.projects
              // resolve(response)
            } else {
              // return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            return reject(error.message)
          })
      })

      /*
      // STEP 1.2 Patch Organization and re-write only left projects
      return new Promise((resolve, reject) => {
        this.$httpBend
          .patch('jsonapi/node/organization/' + organizationId + '/relationships/field_projects', body, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(response => {
            // console.log(response)
            if (response.status === 204) {
              // STEP 2. Delete project node from bend
              deleteprojectnode()

              // STEP 3. Commit vuex to set left projects
              this.$store.commit('bend/setOrganizationProjects', { projects: leftProjects })

              // STEP 4. Delete project folder completely in OCPU Server
              deleteprojectfolder()
              this.projects = org.projects
            } else {
              // return resolve(false)
            }
            // console.log(response)
          })
          .catch(error => {
            // console.log(error)
            return reject(error.message)
          })
      })
      */
    },
  },
}
