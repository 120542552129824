/**
 * Get only projects where current user is listed under Project roles
 */
import axios from 'axios'

export default {
  data: () => ({

    dDatasetTemplate: [
      // { pack: 'csv', color: 'success', syncdate: '', link: '', loading: false, icon: 'mdi-table', title: 'CSV', label: 'mpref.dset-csv', selected: false },
      { pack: 'excel', color: 'info', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'Excel', label: 'mpref.dset-excel', selected: false },
      { pack: 'pexcel', color: 'blue', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'pExcel', label: 'mpref.dset-pexcel', selected: false },
      { pack: 'stata', color: 'warning', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'Stata', label: 'mpref.dset-stata', selected: false },
      { pack: 'rds', color: 'indigo', syncdate: '', link: '', loading: false, icon: 'mdi-alpha-r-circle', title: 'Rds', label: 'mpref.dset-r', selected: false },
      { pack: 'spss', color: 'primary', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SPSS', label: 'mpref.dset-spss', selected: false },
      { pack: 'sas', color: 'error', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SAS', label: 'mpref.dset-sas', selected: false },
      { pack: 'arcgis', color: 'secondary', syncdate: '', link: '', loading: false, icon: 'mdi-earth', title: 'ArcGIS', label: 'mpref.dset-arcgis', selected: false },
    ],
    dDatasets: [
      // { pack: 'csv', color: 'success', syncdate: '', link: '', loading: false, icon: 'mdi-table', title: 'CSV', label: 'mpref.dset-csv', selected: false },
      { pack: 'excel', color: 'info', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'Excel', label: 'mpref.dset-excel', selected: false },
      { pack: 'pexcel', color: 'blue', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'pExcel', label: 'mpref.dset-pexcel', selected: false },
      { pack: 'stata', color: 'warning', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'Stata', label: 'mpref.dset-stata', selected: false },
      { pack: 'rds', color: 'indigo', syncdate: '', link: '', loading: false, icon: 'mdi-alpha-r-circle', title: 'Rds', label: 'mpref.dset-r', selected: false },
      { pack: 'spss', color: 'primary', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SPSS', label: 'mpref.dset-spss', selected: false },
      { pack: 'sas', color: 'error', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SAS', label: 'mpref.dset-sas', selected: false },
      { pack: 'arcgis', color: 'secondary', syncdate: '', link: '', loading: false, icon: 'mdi-earth', title: 'ArcGIS', label: 'mpref.dset-arcgis', selected: false },
    ],

    selectedLang: { code: '', full: '', short: '' },

    loadingdata: true,

  }),

  methods: {

    // Update this.dDatasets value (selected) to true according to packs array list
    async getdDataset () {
      // Wait while we get data from ocpu
      await this.dBlockGet('dDataset')

      // selected datasets as array
      const selectedDset = this.$store.state.main.dsb.dDataset.filter(x => x.selected === true).map(x => x.pack)

      // Update this.dDatasets
      this.dDatasets.map(dSet => {
        if (selectedDset.includes(dSet.pack)) {
          dSet.selected = true

          // get Syncronization data for each selected dataset
          const dsname = dSet.title
          // console.log(dsname)
          this.getdsSyncData(dsname.toLowerCase())
        }
      })
      this.loadingdata = false
    },

    // Get Sync details and update
    getdsSyncData (pack) {
      // console.log(dsname)
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      const main = this.$store.state.main
      const fpath = `${main.orgid}/${main.ap.prjid}/${main.afid}`
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/ocgetsyncdata/json`, { fpath: fpath, filetype: pack })
          .then(response => {
            // console.log(response)
            if (response.data.length > 0) {
              const syncData = JSON.parse(response.data[0])
              const pos = this.dDatasets.map(function (e) { return e.title.toLowerCase() }).indexOf(pack)
              this.dDatasets[pos].loading = false
              this.dDatasets[pos].syncdate = syncData.syncDate
              this.dDatasets[pos].link = syncData.link
            }
          })
      })
    },

    // Generate new dataset and save sync data to ocpu
    async genDataset (pack, i) {
      // console.log(pack)
      // console.log(this.finalDataset)
      // console.log(this.dDatasets)
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      // const dsname = pack.substr(2)
      const pos = this.dDatasets.map(function (e) { return e.title.toLowerCase() }).indexOf(pack)
      // console.log(pos)
      this.dDatasets[pos].loading = true

      const main = this.$store.state.main
      const fpath = `${main.orgid}/${main.ap.prjid}/${main.afid}`

      // STEP 2. Save Sync details into ocpu's file type folder
      const postSyncData = (syncData) => {
        // console.log('postSyncData da')
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocsavesyncdata/json`, { syncdata: syncData, fpath: fpath, filetype: pack })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                return resolve()
              }
            })
            .catch(error => {
              // console.log(error)
              // if (error.response.status !== 201) {
              this.snackBar = {
                type: 'error',
                mode: 'multi-line',
                timeout: 4500,
                title: `${this.$t('common.error')}`,
                text: 'Something is gone wrong, sorry. Please, inform UDACAPI Admin',
                visible: true,
              }
              //   reject(error)
              // }
              reject(error)
            })
        })
      }

      const props = {
        orgid: main.orgid,
        prjid: main.ap.prjid,
        formid: main.afid,
        filetype: pack.toLowerCase(),
        langcode: this.selectedLang.code,
      }
      // STEP 1. generate new dataset
      return new Promise((resolve, reject) => {
        // console.log(props)
        this.$httpOcpu.post(`/${ocpuPack}/R/oczipfiles/json`, props)
          .then(async response => {
            // console.log(response)
            // console.log(response.headers.location)
            const d = new Date()
            // const syncDate = d.toISOString().slice(0, 19) + '+00:00'

            const syncDate = d.toISOString().slice(0, 19) + 'Z'
            const link = `${response.headers.location}files/${response.data[0]}`
            const syncData = { link: link, syncDate: syncDate }

            // post Syncronization Data to ocpu
            // STEP 1.2 run postSyncData
            await postSyncData(JSON.stringify(syncData))
            this.getdsSyncData(pack)
          })
          .catch(error => {
            // console.log(error)
            if (error.response.status !== 201) {
              this.snackBar = {
                type: 'error',
                mode: 'multi-line',
                title: 'Error during generation of file',
                text: 'Something is gone wrong, sorry. Please, inform UDACAPI Admin',
                visible: true,
              }
              this.dDatasets[pos].loading = false
              reject(error)
            }
            // reject(error)
          })
      })
    },

    // Used to activate generate button if file was generated more than 24 hours ago
    dateDiffInHours (syncdate) {
      const nowdate = new Date()
      const sncdate = new Date(syncdate)
      const _MS_PER_DAY = 1000 * 60 * 60
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(nowdate.getFullYear(), nowdate.getMonth(), nowdate.getDate())
      const utc2 = Date.UTC(sncdate.getFullYear(), sncdate.getMonth(), sncdate.getDate())
      const diff = Math.floor((utc1 - utc2) / _MS_PER_DAY)
      // console.log(diff)

      return diff
    },

    // Constructs download link for datasets
    dataLinks (dDataset) {
      // console.log(dDataset)
      if (dDataset.link.length > 0) {
        // console.log(dDataset)
        const sLink = dDataset.link
        const fileName = sLink.substring(sLink.lastIndexOf('/') + 1)
        // console.log(fileName)
        axios({
          url: sLink,
          method: 'GET',
          responseType: 'blob',
        }).then(response => {
          // console.log(response)
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
          window.URL.revokeObjectURL(fileURL)
        }).catch(error => {
          // console.log(error.response.status)
          if (error.response.status === 400) {
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              text: 'No file existing. Please, generate a new file.',
              visible: true,
            }
          }
        })
      }
    },

  },
}
