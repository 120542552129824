<template>
  <v-container
    id="project-list"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Default orgTitle value is UDA Consulting -->
    <base-v-component
      :organization="orgTitle"
      :heading="$t('section-title.project-list')"
      link
    />

    <!-- Button: Add New Project -->
    <v-btn
      v-if="isOwner()"
      color="primary"
      @click="dialogAddProject = true"
    >
      <v-icon left>
        mdi-tag-plus
      </v-icon>
      {{ $t('project-list.new-project') }}
    </v-btn>
    <!-- <v-btn
      v-if="isOwner()"
      color="primary"
      @click="snackBar.visible = true"
    >
      <v-icon left>
        mdi-tag-plus
      </v-icon>
      Snack Bar
    </v-btn> -->

    <!-- MAIN Part: Projects -->
    <v-row>
      <!-- About Project - body text -->
      <v-col
        cols="12"
      >
        <!-- Project Forms Card -->
        <v-card
          v-for="(prj, i) in projects"
          :key="i"
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            color="indigo"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-tag
            </v-icon>
            <v-toolbar-title>{{ prj.title }}</v-toolbar-title>
            <v-spacer />

            <!-- Settings -->
            <v-btn
              dark
              icon
              large
              @click="pSettings({ project: prj })"
            >
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-title>
            <v-spacer />
            <v-btn
              color="primary"
              @click="addNewForm(prj)"
            >
              <v-icon left>
                mdi-text-box-plus
              </v-icon>
              {{ $t('project-list.new-form') }}
            </v-btn>
          </v-card-title>

          <!-- List of Form -->
          <v-card-text v-if="projects.length > 0">
            <v-col
              class="px-0"
            >
              <!-- <div v-if="projectForms(i).length > 0">
                {{ $t('project-list.form-list') }}
              </div>-->

              <v-simple-table
                v-if="projectForms(i).length > 0"
              >
                <!-- Table heders -->
                <thead>
                  <tr class="mx-0">
                    <th class="caption grey--text">
                      #
                    </th>
                    <th class="caption grey--text">
                      {{ $t('project-list.form-title') }}
                    </th>
                    <th class="caption grey--text">
                      {{ $t('table-header.phase') }}
                    </th>
                    <th class="caption grey--text">
                      {{ $t('table-header.started') }}
                    </th>
                    <th class="caption grey--text">
                      {{ $t('table-header.submissions') }}
                    </th>
                    <th class="caption grey--text">
                      {{ $t('table-header.updated') }}
                    </th>
                    <th class="caption grey--text text-right">
                      {{ $t('table-header.actions') }}
                    </th>
                  </tr>
                </thead>

                <!-- Table body -->
                <tbody>
                  <tr
                    v-for="(form, f) in projectForms(i)"
                    :key="form.id"
                  >
                    <td>{{ f+1 }}</td>
                    <td>{{ form.title }}</td>
                    <td>
                      <v-chip
                        :color="formstate({p: form.odksettings.phase, s: form.odksettings.state})"
                      >
                        <v-icon
                          v-if="form.odksettings.phase==='active'"
                          left
                        >
                          {{ stateicon(form.odksettings.state) }}
                        </v-icon>
                        {{ form.odksettings.phase }}
                      </v-chip>
                    </td>
                    <td>{{ form.startDate }}</td>
                    <td>
                      {{ (form.odksettings.phase==='active') ? form.submissions : '---' }}
                    </td>
                    <td>
                      <timeago
                        :datetime="('syncDate' in form) ? form.syncDate : Date()"
                        :auto-update="60"
                      />
                    </td>
                    <td
                      class="text-right"
                    >
                      <span
                        v-for="a in formButtons(form)"
                        :key="a.id"
                      >
                        <v-tooltip
                          :key="a.path"
                          bottom
                        >
                          <template #activator="{ on }">
                            <!-- normal buttons -->
                            <v-btn
                              v-if="!a.attach"
                              small
                              dark
                              icon
                              :disabled="a.disabled || loadDialog"
                              @click="formActions({ prj: prj, form: form, button: a })"
                            >
                              <v-icon
                                :color="a.color"
                                v-on="on"
                              >
                                {{ a.icon }}
                              </v-icon>
                            </v-btn>

                            <!-- attachment button -->
                            <v-badge
                              v-else
                              dot
                              color="red"
                              overlap
                              :value="moreUpload(form)"
                            >
                              <v-btn
                                small
                                dark
                                icon
                                @click="formActions({ prj: prj, form: form, button: a })"
                              >
                                <v-icon
                                  :color="a.color"
                                  v-on="on"
                                >
                                  {{ a.icon }}
                                </v-icon>
                              </v-btn>
                            </v-badge>
                          </template>
                          <span>{{ translateMe(a.tooltip) }}</span>
                        </v-tooltip>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-card-text>
          <v-card-text
            v-else
            class="text-center"
          >
            <h6 class="display-1 mb-1 grey--text">
              {{ $t('project-list.new-project') }}
            </h6>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <v-row justify="center">
      <!-- ADD New Project Dialog -->
      <v-dialog
        v-model="dialogAddProject"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('project-list.new-project') }}</span>
          </v-card-title>
          <v-card-text
            v-if="projectUsed < projectLimit"
          >
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(addProject)">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="$t('project-list.project-title')"
                >
                  <v-text-field
                    v-model="projectName"
                    outlined
                    counter="25"
                    dense
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('project-list.project-title')"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Default server"
                >
                  <v-checkbox
                    v-model="ownServerUse"
                    :label="$t('project-list.own-server')"
                    :error-messages="errors"
                  />
                </validation-provider>

                <v-container
                  v-if="ownServerUse"
                  class="pa-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="ODK Server"
                    :rules="{ required: true, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
                  >
                    <v-text-field
                      v-model="ownServerUrl"
                      outlined
                      dense
                      :error-messages="errors"
                      color="secondary"
                      :label="$t('project-list.server-url')"
                      prepend-icon="mdi-server-network"
                    />
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="Username"
                    rules="required"
                  >
                    <v-text-field
                      v-model="ownServerUser"
                      outlined
                      dense
                      :error-messages="errors"
                      :label="`${$t('register.user-name')}...`"
                      prepend-icon="mdi-account"
                    />
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('register.password')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="ownServerPass"
                      outlined
                      dense
                      :error-messages="errors"
                      color="secondary"
                      :label="$t('register.password')"
                      prepend-icon="mdi-lock-outline"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPass ? 'text' : 'password'"
                      @click:append.prevent="showPass = !showPass"
                    />
                  </validation-provider>
                </v-container>
                <!--
                <small class="mb-1 grey--text">
                  Left Project limit: 2
                </small>
                -->
                <v-row>
                  <v-spacer />
                  <v-btn
                    class="mr-3"
                    text
                    @click="dialogAddProject = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn
                    color="success"
                    text
                    class="mr-3"
                    type="submit"
                  >
                    {{ $t('common.register') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
          <v-card-text
            v-else
          >
            <div class="text--primary">
              {{ $t('payment.upgrade-needs') }}
            </div>
            <v-row>
              <v-spacer />
              <v-btn
                class="mr-3"
                text
                @click="dialogAddProject = false"
              >
                {{ $t('common.close') }}
              </v-btn>
              <v-btn
                color="success"
                text
                class="mr-3"
                @click="$router.push({ name: 'Subscription' })"
              >
                {{ $t('common.Upgrade') }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- ADD New Form Dialog -->
      <v-dialog
        v-model="dialogAddForm"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('project-list.new-form') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- New Form Section -->
              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(saveNewForm)">
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('project-list.form-title')"
                      >
                        <v-text-field
                          v-model="formSettings.title"
                          class="mt-2 my-0 py-0"
                          :label="$t('project-list.form-title')"
                          :hint="$t('project-list.form-title-hint')"
                          color="secondary"
                          outlined
                          dense
                          required
                          counter="50"
                          :error-messages="errors"
                          append-icon="mdi-help-circle"
                          @click:append="settingsHelp()"
                          @input="formAutoName"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      md="8"
                      sm="10"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('project-list.instance-name')"
                      >
                        <v-text-field
                          v-model="formSettings.id"
                          class="my-0"
                          :label="$t('project-list.instance-name')"
                          :hint="$t('project-list.instance-name')"
                          color="secondary"
                          outlined
                          dense
                          required
                          :disabled="changeDraft"
                          counter="25"
                          :error-messages="errors"
                          :readonly="!formNameEdit"
                          append-icon="mdi-help-circle"
                          :prepend-icon="formEditIcon"
                          @click:prepend="editName"
                          @click:append="settingsHelp()"
                          @input="noSpace"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      md="4"
                      sm="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="positive|required"
                        :name="$t('project-list.version')"
                      >
                        <v-text-field
                          v-model="formSettings.version"
                          class="my-0"
                          :label="$t('project-list.version')"
                          :hint="$t('project-list.version-hint')"
                          color="secondary"
                          type="number"
                          outlined
                          dense
                          :error-messages="errors"
                          append-icon="mdi-help-circle"
                          @click:append="settingsHelp()"
                        />
                        <!--
                            <v-text-field
                              :v-model.number="formversion"
                              :label="$t('project-list.version')"
                              :hint="$t('project-list.version-hint')"
                              append-outer-icon="mdi-plus-circle-outline"
                              prepend-icon="mdi-minus-circle-outline"
                              :error-messages="errors"
                              @click:append-outer="settings.version++"
                              @click:prepend="settings.version--"
                            />
                            -->
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <v-textarea
                          v-model="formDescription"
                          :error-messages="errors"
                          outlined
                          rows="2"
                          dense
                          color="secondary"
                          :label="$t('project-list.description')"
                          :hint="$t('project-list.description-hint')"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Default server"
                      >
                        <v-checkbox
                          v-model="formAdvancedSetting"
                          :label="$t('project-list.advanced-settings')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                      <v-container
                        v-if="formAdvancedSetting"
                        class="pa-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="alpha_num"
                          :name="$t('project-list.public-key')"
                        >
                          <v-text-field
                            v-model="formSettings.public_key"
                            class="my-0"
                            :label="$t('project-list.public-key')"
                            :hint="$t('project-list.public-key-hint')"
                            color="secondary"
                            outlined
                            dense
                            :error-messages="errors"
                            append-icon="mdi-help-circle"
                            @click:append="settingsHelp()"
                          />
                        </validation-provider>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer />
                    <v-btn
                      class="mr-3"
                      text
                      @click="dialogAddForm = false"
                    >
                      {{ $t('common.cancel') }}
                    </v-btn>
                    <v-btn
                      color="success"
                      text
                      class="mr-3"
                      type="submit"
                    >
                      {{ $t('common.save') }}
                    </v-btn>
                  </v-row>
                </form>
              </validation-observer>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Upload XLSForm Dialog -->
      <v-dialog
        v-model="dialogUploadForm"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('project-list.upload-xlsform') }}</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(uploadForm)">
                <v-row>
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="XLSForm"
                      rules="required"
                    >
                      <v-file-input
                        v-model="xlsform"
                        class="mx-4"
                        :error-messages="errors"
                        :label="$t('project-list.select-xlsform')"
                        dense
                        outlined
                        full-width
                        accept=".xlsx"
                        @change="xlsFormSelected()"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Language"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="defLang"
                        class="mx-4"
                        :items="formLang"
                        :error-messages="errors"
                        :loading="xlsfuploading"
                        item-text="label"
                        item-value="code"
                        :label="$t('project-list.def-lang')"
                        prepend-icon="mdi-web"
                        dense
                        outlined
                        return-object
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <small class="mx-6 grey--text">
                    {{ $t('project-list.xlsform-note') }}
                  </small>
                </v-row>
                <v-row>
                  <v-btn
                    color="warning"
                    text
                    @click="getxlsformtemplate()"
                  >
                    {{ $t('project-list.xlsform-sample') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    text
                    @click="dialogUploadForm = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn
                    color="success"
                    text
                    type="submit"
                    :loading="loading"
                    :disabled="uploadDisabled"
                  >
                    {{ $t('common.upload') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Form ATTACHMENTS Dialog -->
      <v-dialog
        v-model="dialogAttachment"
        persistent
        max-width="550"
      >
        <v-card
          outlined
        >
          <v-toolbar
            flat
            color="grey"
            dark
            dense
          >
            <v-icon left>
              mdi-cog
            </v-icon>
            <v-toolbar-title>{{ $t('project-list.form-attach') }}</v-toolbar-title>
          </v-toolbar>

          <!-- Table of uploaded attachments -->
          <v-card-text>
            <v-simple-table
              fixed-header
              height="300px"
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('table-header.name') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.type') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.uploaded') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.actions') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in uplAttachs"
                    :key="item.name[0]"
                  >
                    <td>{{ item.name[0] }}</td>
                    <td>{{ item.type[0] }}</td>
                    <td>
                      <timeago
                        :datetime="item.updatedAt[0]"
                        :auto-update="60"
                      />
                    </td>
                    <td
                      class="text-right"
                    >
                      <v-tooltip
                        bottom
                      >
                        <template #activator="{ on }">
                          <v-btn
                            small
                            icon
                            color="error"
                            dark
                            v-on="on"
                            @click="deleteAttachment(item.name[0])"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('tooltip.delete') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <!-- Upload the next attachment part -->
          <v-card-text
            v-if="expAttachs !== 'None'"
            class="py-0"
          >
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(uploadAttachment)">
                <v-row>
                  <small class="ml-3 mt-3 grey--text">
                    ({{ $t('project-list.attach-left') }}: {{ attachments(selectedForm) }})
                  </small>
                  <v-spacer />
                  <validation-provider
                    v-slot="{ errors }"
                    name="Attachment"
                    rules="required"
                  >
                    <v-file-input
                      v-model="attached"
                      class="my-2 mr-0"
                      :error-messages="errors"
                      :placeholder="`${$t('project-list.select-file')} '${expAttachs.name[0]}'`"
                      clearable
                      prepend-icon="mdi-paperclip"
                      dense
                      outlined
                      full-width
                      :accept="attachExtension(expAttachs.name[0])"
                    />
                  </validation-provider>
                  <!-- <v-tooltip
                    bottom
                  >
                    <template #activator="{ on }"> -->
                  <v-btn
                    color="warning"
                    :loading="loading"
                    dark
                    icon
                    large
                    type="submit"
                  >
                    <v-icon>
                      mdi-cloud-upload
                    </v-icon>
                  </v-btn>
                  <!-- </template>
                    <span>{{ $t('common.upload') }}</span>
                  </v-tooltip> -->
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer />
            <v-btn
              text
              @click="dialogAttachment = false"
            >
              {{ $t('common.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Show QR Code Dialog -->
      <v-dialog
        v-model="dialogQR"
        max-width="210"
      >
        <v-card>
          <!--
          <v-card-title>
            <v-spacer />
            <v-icon
              aria-label="Close"
              @click="dialogQR = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          -->

          <v-card-text
            class="text-center ma-0 pa-1"
          >
            <qrcode
              ref="qr"
              :value="qrCode"
              :options="{
                width: 200,
                margin: 1,
              }"
            />
            <v-btn
              color="success"
              icon
              dark
              @click="saveQRCode()"
            >
              <v-icon
                aria-label="Close"
                @click="dialogQR = false"
              >
                mdi-download
              </v-icon>
              {{ $t('common.download') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Draft form settings dialog -->
      <v-dialog
        v-model="dialogDraftSettings"
        persistent
        max-width="400px"
      >
        <v-card
          outlined
          class="mt-1"
        >
          <v-toolbar
            flat
            color="grey"
            dark
            dense
          >
            <v-icon left>
              mdi-cog
            </v-icon>
            <v-toolbar-title>{{ $t('section-title.form-settings') }}</v-toolbar-title>
            <v-spacer />
            <v-tooltip
              v-if="selectedForm.odksettings.phase == 'draft'"
              bottom
            >
              <template
                v-if="myAccessLevel() === 'manager-staff'"
                #activator="{ on }"
              >
                <v-btn
                  color="warning"
                  fab
                  dark
                  v-on="on"
                  @click="changeDraftSetting()"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('common.update') }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            {{ selectedForm.odksettings.title }}
          </v-card-text>
          <v-card-text class="body-1 grey--text text--darken-2 py-0">
            <div> {{ $t('form-settings.instance') }}: {{ selectedForm.odksettings.id }} </div>
            <div> {{ $t('form-settings.version') }}: {{ `${selectedForm.odksettings.version} (${selectedForm.odksettings.public_key.length > 0 ? $t('form-settings.encrypted') : $t('form-settings.not-encrypted')})` }} </div>
            <div> {{ $t('project-list.form-attach') }}: {{ selectedForm.odksettings.attachment.length }} </div>
            <div> {{ $t('form-settings.description') }}: {{ selectedForm.body }} </div>
          </v-card-text>
          <v-card-actions>
            <v-tooltip
              v-if="myAccessLevel() === 'manager-staff'"
              bottom
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  dark
                  color="error"
                  v-on="on"
                  @click="dialogDelete = true"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('common.delete') }}</span>
            </v-tooltip>
            <v-spacer />
            <v-btn
              text
              @click="closeDraftSetting()"
            >
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Form Dialog -->
      <v-dialog
        v-model="dialogDelete"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular primary white--text py-2">
            {{ $t('common.heads-up') }}
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(deleteform)">
              <v-card-text class="pb-1">
                {{ $t('form-settings.will-del-form') }} <b>{{ selectedForm.title }}</b> (<b>{{ selectedForm.submissions }}</b> {{ $t('common.submissions') }}).
                {{ $t('form-settings.action-note') }}
                <validation-provider
                  v-slot="{ errors }"
                  name="Agree checkbox"
                  rules="required"
                >
                  <v-checkbox
                    v-model="agreecheckbox"
                    :error-messages="errors"
                    :label="$t('form-settings.sure')"
                    type="checkbox"
                    required
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogDelete = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  text
                  type="submit"
                >
                  {{ $t('common.delete') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Export Data Dialog -->
      <v-dialog
        v-model="dialogExport"
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular py-2">
            {{ $t('sections.data-export') }}
          </v-card-title>
          <v-card-text>
            <v-progress-linear
              v-if="loadingdata"
              indeterminate
              height="2"
            />
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('mpref.dset-dwnl') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.generated') }}
                    </th>
                    <th class="text-right">
                      {{ $t('table-header.actions') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(type, i) in finalDataset"
                    :key="i"
                  >
                    <td>{{ $t(`mpref.dset-${type.pack}`) }}</td>
                    <td>{{ dateDiffInHours(type.syncdate) }}</td>
                    <td
                      v-if="type.syncdate !== ''"
                    >
                      <timeago
                        :datetime="type.syncdate"
                        :locale="$i18n.locale"
                        :auto-update="60"
                      />
                    </td>
                    <td
                      v-else
                    />
                    <td
                      class="text-right"
                    >
                      <v-tooltip
                        v-if="(type.syncdate !== '') && (dateDiffInHours(type.syncdate) < 24)"
                        bottom
                      >
                        <template #activator="{ on }">
                          <v-btn
                            small
                            icon
                            :color="type.color"
                            dark
                            v-on="on"
                            @click="dataLinks(type)"
                          >
                            <v-icon>
                              {{ type.icon }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('common.download') }}</span>
                      </v-tooltip>
                      <v-tooltip
                        v-else
                        bottom
                      >
                        <template #activator="{ on }">
                          <v-btn
                            small
                            icon
                            color="secondary"
                            dark
                            :disabled="!subscIsActive"
                            v-on="on"
                            @click="genDataset(type.pack)"
                          >
                            <v-icon>
                              mdi-folder-refresh-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('dashboard.ddset-gen') }}</span>
                      </v-tooltip>
                      <!-- <v-btn
                        small
                        icon
                        dark
                        :disabled="true"
                        color="primary"
                      >
                        <v-icon>
                          mdi-file-excel
                        </v-icon>
                      </v-btn> -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            {{ subscIsActive }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogExport = false"
            >
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Loading dialog -->
      <v-dialog
        v-model="loadDialog"
        hide-overlay
        persistent
        min-width="250"
        max-width="400"
      >
        <v-card
          color="warning"
          dark
        >
          <v-card-text
            class="white--text"
          >
            {{ loadText }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import { extend } from 'vee-validate'
  // eslint-disable-next-line camelcase
  import { alpha_num, regex } from 'vee-validate/dist/rules'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'

  import projects from '@/mixins/projects'
  import projectRoles from '@/mixins/project-roles'
  import odkForms from '@/mixins/forms'
  import userAccess from '@/mixins/user-access'
  import dashboard from '@/mixins/dashboard'
  import dataExport from '@/mixins/data-export'
  import payment from '@/mixins/payment'
  import utilities from '@/mixins/utilities'

  import axios from 'axios'
  import VueQrcode from '@chenfengyuan/vue-qrcode'
  import SnackBarCard from '@/components/SnackbarCard'

  Vue.component(VueQrcode.name, VueQrcode)

  const subscription = useSubscriptionStore()

  // Vee Validate.
  extend('alpha_num', alpha_num)
  extend('regex', regex)
  extend('positive', value => {
    return value >= 1 && value <= 99
  })

  export default {
    name: 'ProjectList',

    components: {
      SnackBarCard,
      // FormDetails: () => import('./components/FormDetails'),
    },

    mixins: [
      projects,
      projectRoles,
      odkForms,
      userAccess,
      dashboard,
      dataExport,
      payment,
      utilities,
    ],

    data: () => ({
      projects: [],

      ap: {},
      af: {},

      search: undefined,

      actions: [
        { color: 'info', icon: 'mdi-account' },
        { color: 'success', icon: 'mdi-pencil' },
        { color: 'error', icon: 'mdi-close' },
      ],

      selectedProject: [],
      prjid: '',
      selectedForm: { body: '', odksettings: { public_key: '', attachment: [] } },
      formid: '',
      formEditIcon: 'mdi-pencil',
      formSettings: {
        title: '',
        id: '',
        version: 1,
        attachment: [],
        public_key: '',
        state: 'open',
        phase: 'draft',
      },
      formDescription: '',
      formNameEdit: false,
      formAdvancedSetting: false,

      xlsform: null,
      formLang: [],
      defLang: [],
      loading: false,
      uploadDisabled: false,

      dialogAddProject: false,
      dialogAddForm: false,
      dialogUploadForm: false,
      dialogAttachment: false,
      dialogQR: false,
      dialogDraftSettings: false,
      dialogDelete: false,
      dialogExport: false,

      agreecheckbox: false,

      changeDraft: false,

      xlsfuploading: false,
      loadDialog: false,
      loadText: 'Please Stend by',

      actionbuttons: [
        { id: 'dashboard', tooltip: 'tooltip.dashboard', icon: 'mdi-view-dashboard', color: 'indigo', to: 'Dashboard', state: 'active' },
        // { id: 'monitor', tooltip: 'tooltip.monitoring-pref', icon: 'mdi-monitor-edit', color: 'secondary', to: 'Monitoring Preferences', state: 'active' },
        { id: 'sync', tooltip: 'tooltip.sync', icon: 'mdi-sync', color: 'secondary', to: '', state: 'active' },
        { id: 'export', tooltip: 'sections.data-export', icon: 'mdi-database-export', color: 'indigo', to: '', state: 'active' },
        { id: 'settings', tooltip: 'tooltip.settings', icon: 'mdi-cog', color: 'warning', to: 'Settings', state: 'active' },

        { id: 'design', tooltip: 'tooltip.design-form', icon: 'mdi-order-bool-ascending-variant', color: 'secondary', to: 'Form Desing', state: 'draft' },
        // { id: 'odkform', tooltip: 'tooltip.design-form', icon: 'mdi-order-bool-ascending-variant', color: 'secondary', to: 'ODK Form', state: 'draft' },
        { id: 'upload', tooltip: 'tooltip.upload-xlsform', icon: 'mdi-file-excel', color: 'secondary', to: 'Form Upload', state: 'draft' },
        { id: 'settingdrf', tooltip: 'tooltip.settings', icon: 'mdi-cog', color: 'warning', to: 'Draft Form Settings', state: 'draft' },

        { id: 'publish', tooltip: 'tooltip.publish-form', icon: 'mdi-earth', color: 'success', to: '', state: 'deployed', attach: false },
        { id: 'abandon', tooltip: 'tooltip.abandon-form', icon: 'mdi-cancel', color: 'error', to: '', state: 'deployed', attach: false },
        { id: 'attachment', tooltip: 'tooltip.attachments', icon: 'mdi-paperclip', color: 'secondary', to: 'Draft Form Settings', state: 'deployed', attach: true },
        { id: 'qrcode', tooltip: 'tooltip.qr-test', icon: 'mdi-qrcode', color: 'indigo', to: '', state: 'deployed', attach: false },
        { id: 'preview', tooltip: 'tooltip.preview', icon: 'mdi-file-eye', color: 'primary', to: '', state: 'deployed', attach: false },
        { id: 'download', tooltip: 'tooltip.dwntest-data', icon: 'mdi-folder-download', color: 'indigo', to: '', state: 'deployed', attach: false },
        { id: 'settingdpf', tooltip: 'tooltip.settings', icon: 'mdi-cog', color: 'warning', to: 'Draft Form Settings', state: 'deployed', attach: false },
      ],

      attached: null,
      qrCode: '',
      projectName: '',
      ownServer: false,
      ownServerUse: false,
      ownServerUrl: '',
      ownServerUser: '',
      ownServerPass: '',
      showPass: false,

      // New snackbar notification
      snackBar: {
        visible: false,
        direction: 'bottom center',
      },
      tform: {},
    }),

    computed: {
      orgTitle () {
        if (this.$store.state.bend.organization !== undefined) {
          return this.$store.state.bend.organization.title
        } else {
          return 'UDACAPI'
        }
      },
      // Check if organization is entered
      isOrganization () {
        return ('id' in this.$store.state.bend.organization)
      },

      roleProject () {
        return (this.usersProjects())
      },

      roleMy () {
        return (this.usersProjects())
      },

      mySubsc () {
        return subscription.activeSubsc
      },

      projectLimit () {
        let limit = subscription.features[this.mySubsc.code].projects
        // using a regular expression to replace the last "+" character with an empty string
        limit = limit.replace(/\+$/, '')

        return limit
      },

      projectUsed () {
        return this.$store.state.bend.organization.projects.length
      },

      experimental () {
        const org = this.$store.state.bend.organization
        if ('tester' in org) {
          return org.tester
        } else {
          return false
        }
      },

      subscIsActive () {
        return this.isSubscActive()
      },

      uplAttachs () {
        const uploaded = this.selectedForm.odksettings.attachment.filter(a => a.exists[0] === true)
        // console.log(uploaded)
        return uploaded
        // return this.selectedForm.odksettings.attachment.filter(a => a.exists[0] === true)
      },

      expAttachs () {
        let attachs
        if ('odksettings' in this.selectedForm) {
          const expected = this.selectedForm.odksettings.attachment.filter(a => a.exists[0] === false)
          // console.log(expected)
          if (expected.length === 0) {
            attachs = 'None'
          } else {
            attachs = expected[0]
          }
        }
        return attachs
      },

      // Return only selected types of datasets
      finalDataset () {
        // console.log(this.dDatasets[0].syncdate)
        // console.log(Date() - this.dDatasets[0].syncdate)
        return this.dDatasets.filter(function (ds) {
          return ds.selected
        })
      },

      actionbuttons2 () {
        return [
          // { id: 'dashboard', size: 'small', tooltip: this.$t('tooltip.dashboard'), icon: 'mdi-view-dashboard', color: 'indigo', to: 'Dashboard' },
          // { id: 'monitor', size: 'small', tooltip: this.$t('tooltip.monitoring-pref'), icon: 'mdi-monitor-edit', color: 'secondary', to: 'Monitoring Preferences' },
          // { id: 'sync', size: 'small', tooltip: this.$t('tooltip.sync'), icon: 'mdi-sync', color: 'secondary', to: '' },
          // { id: 'settings', size: 'small', tooltip: this.$t('tooltip.settings'), icon: 'mdi-cog', color: 'warning', to: 'Active Form Settings' },

          { id: 'dashboard', tooltip: this.$t('tooltip.dashboard'), icon: 'mdi-view-dashboard', color: 'indigo', to: 'Dashboard', state: 'active' },
          // { id: 'monitor', tooltip: this.$t('tooltip.monitoring-pref'), icon: 'mdi-monitor-edit', color: 'secondary', to: 'Monitoring Preferences', state: 'active' },
          { id: 'sync', tooltip: this.$t('tooltip.sync'), icon: 'mdi-sync', color: 'secondary', to: '', state: 'active' },
          { id: 'settings', tooltip: this.$t('tooltip.settings'), icon: 'mdi-cog', color: 'warning', to: 'Settings', state: 'active' },

          { id: 'design', tooltip: this.$t('tooltip.design-form'), icon: 'mdi-order-bool-ascending-variant', color: 'secondary', to: 'Form Desing', state: 'draft' },
          { id: 'upload', tooltip: this.$t('tooltip.upload-xlsform'), icon: 'mdi-file-excel', color: 'secondary', to: 'Form Upload', state: 'draft' },
          { id: 'settingdrf', tooltip: this.$t('tooltip.settings'), icon: 'mdi-cog', color: 'warning', to: 'Draft Form Settings', state: 'draft' },

          { id: 'publish', tooltip: this.$t('tooltip.publish-form'), icon: 'mdi-earth', color: 'success', to: '', state: 'deployed', attach: false },
          { id: 'abandon', tooltip: this.$t('tooltip.abandon-form'), icon: 'mdi-cancel', color: 'error', to: '', state: 'deployed', attach: false },
          { id: 'attachment', tooltip: this.$t('tooltip.attachments'), icon: 'mdi-paperclip', color: 'secondary', to: 'Draft Form Settings', state: 'deployed', attach: true },
          { id: 'qrcode', tooltip: this.$t('tooltip.qr-test'), icon: 'mdi-qrcode', color: 'indigo', to: '', state: 'deployed', attach: false },
          { id: 'preview', tooltip: this.$t('tooltip.preview'), icon: 'mdi-file-eye', color: 'primary', to: '', state: 'deployed', attach: false },
          { id: 'download', tooltip: this.$t('tooltip.dwntest-data'), icon: 'mdi-folder-download', color: 'indigo', to: '', state: 'deployed', attach: false },
          { id: 'settingdpf', tooltip: this.$t('tooltip.settings'), icon: 'mdi-cog', color: 'warning', to: 'Draft Form Settings', state: 'deployed', attach: false },
        ]
      },

    },

    mounted () {
      this.initiate()
      this.isOwner()
      // this.tform = this.$store.state.bend.organization.projects[3].forms[0]
      // console.log(this.tform.odksettings)
      // if ('attachment' in this.tform.odksettings) {
      //   // console.log('isAttach')
      //   const attach = this.tform.odksettings.attachment
      //   const moreupload = attach.some(a => a.exists[0] === false)
      //     // console.log(`moreupload ${moreupload}`)
      //   // const alluploaded = attach.some(a => a.exists[0] === false)
      //     // console.log(`alluploaded ${!moreupload}`)
      // }
    },

    methods: {
      async initiate () {
        if (this.isOrganization && !this.$store.state.bend.calls.projects) {
          // console.log('this.getProjects()')
          // if projects was not called
          await this.getProjects()
          this.projects = this.usersProjects()
          // this.projects = this.$store.state.bend.organization.projects
        } else {
          // console.log('else')
          this.projects = this.usersProjects()
          // this.projects = this.$store.state.bend.organization.projects
        }
        if (!this.$store.state.bend.calls.webuser) {
          // console.log('this.getWebuser()')
          // if web user was not called
          this.getWebuser()
        }
      },

      translateMe (val) {
        return this.$t(val)
      },

      // List of all forms of the project for rendering in template
      projectForms (prj) {
        const p = this.projects[prj]
        if ('forms' in p) {
          const active = p.forms.filter(f => f.odksettings.phase === 'active')
          const deployed = p.forms.filter(f => f.odksettings.phase === 'deployed')
          const drafts = p.forms.filter(f => f.odksettings.phase === 'draft')
          const result = active.concat(deployed, drafts)
          return result
        } else {
          return {}
        }
      },

      addNewForm (prj) {
        // console.log(prj)
        this.selectedProject = prj
        // this.$store.dispatch('ocpu/usedForm', {
        //   orgid: this.$store.state.bend.organization.orgid,
        //   prjid: prj.prjid,
        // }).then(res => {
        //   // console.log(res)
        // })
        this.dialogAddForm = true
      },

      // Set of buttons based on form phase
      formButtons (form) {
        // console.log(form.title)
        // let abuttons

        const abuttons = this.actionbuttons.filter(s => s.state === form.odksettings.phase)
        // Disable 'odkform' button if experimental features are not included
        // if (!this.isSubscActive()) {
        //   abuttons.map(obj => {
        //     if (obj.id === 'export') {
        //       Object.assign(obj, { disabled: true })
        //     } else {
        //       Object.assign(obj, { disabled: false })
        //     }
        //     // if (obj.id === 'export') {
        //     //   obj.disabled = true
        //     // } else {
        //     //   obj.disabled = false
        //     // }
        //   })
        // } else {
        //   abuttons.map(obj => {
        //     Object.assign(obj, { disabled: false })
        //   })
        // }

        if (this.moreUpload(form)) {
          // console.log(form.title + ' ala ' + this.attachments(form))
          abuttons.map(obj => {
            // console.log(obj)
            if (obj.id === 'publish') {
              obj.disabled = true
            } else {
              obj.disabled = false
            }
            if (obj.id === 'preview') {
              obj.disabled = true
            } else {
              obj.disabled = false
            }
          })
        }

        // console.log(abuttons)
        return abuttons
      },

      moreUpload (form) {
        // console.log(form.odksettings)
        const attach = form.odksettings.attachment
        const moreupload = attach.some(a => a.exists[0] === false)
        return moreupload
      },

      // Number of attachments
      isAttachment (form) {
        // console.log(('attachment' in form.odksettings))
        if ('attachment' in form.odksettings) {
          if (form.odksettings.attachment.length > 0) {
            // console.log(form.odksettings.attachment[0].exists[0])
            return form.odksettings.attachment[0].exists[0]
          } else {
            // console.log(false)
            return false
          }
        }
      },

      // Number of attachments
      attachments (form) {
        // console.log(form.title)
        let result
        if ('attachment' in form.odksettings) {
          // console.log(form.odksettings)
          const res = form.odksettings.attachment.filter(val => {
            // console.log(val.exists[0])
            return val.exists[0] === false
          })
          // console.log(res.length)
          result = res.length
        } else {
          result = null
        }
        return result
      },

      pSettings (prop) {
        // console.log(prop)
        const ix = this.getIx(prop)

        this.$store.dispatch('main/setIndexes', ix).then(() => {
          // console.log(ix)
          this.$router.push({ name: 'Project Settings' })
        })
      },

      forumActive (item) {
        // console.log(item)
        return (item !== 'active')
      },

      formstate (prop) {
        // console.log(prop)
        const scolor = (prop.p === 'draft') ? 'grey lighten-2'
          : (prop.p === 'active' && prop.s === 'open') ? 'success'
            : (prop.p === 'active' && prop.s === 'closing') ? 'warning'
              : 'grey lighten-1'
        return scolor
      },

      // prepare icon based on form state
      stateicon (prop) {
        switch (prop) {
          case 'open':
            return 'mdi-cloud-check'
          case 'closing':
            return 'mdi-cloud-alert'
          case 'closed':
            return 'mdi-cloud-lock'
          default:
            return 'mdi-cloud'
        }
      },

      async formActions (item) {
        // console.log(item)
        const ix = this.getIx({ project: item.prj, form: item.form })
        this.selectedProject = item.prj
        this.prjid = item.prj.prjid
        this.selectedForm = item.form
        this.formid = item.form.formid
        // console.log(ix)
        // this.selectedForm = item.form
        this.$store.dispatch('main/setIndexes', ix).then(() => {
          switch (item.button.id) {
            // Active form's cases
            case 'sync':
              this.getSubmissions(ix)
              break
            case 'export':
              this.prepDataExport()
              break
            // Deployed form's cases
            case 'publish':
              this.publishDraft({ project: item.prj, form: item.form })
              // this.loading = false
              break
            case 'abandon':
              this.abandonDraft({ project: item.prj, form: item.form })
              break
            case 'qrcode':
              this.showTestCode({ project: item.prj, form: item.form })
              break
            case 'preview':
              this.formPreview({ project: item.prj, form: item.form })
              break
            case 'download':
              this.getDSubmission({ project: item.prj, form: item.form })
              break
            case 'upload':
              this.xlsform = null
              this.uploadDisabled = false
              this.dialogUploadForm = true
              break
            case 'attachment':
              this.attached = null
              this.dialogAttachment = true
              break
            case 'settingdrf':
              this.dialogDraftSettings = true
              break
            case 'settingdpf':
              this.dialogDraftSettings = true
              break

            // All other cases go to specific pages
            default:
              this.$store.dispatch('main/setIndexes', ix).then(() => {
                this.$router.push({ name: item.button.to })
              })
              break
          }
        })
      },

      changeDraftSetting () {
        // console.log(this.formSettings)
        // console.log(this.selectedForm)
        this.changeDraft = true
        this.formDescription = this.selectedForm.body
        this.formSettings = this.selectedForm.odksettings
        this.dialogAddForm = true
      },

      closeDraftSetting () {
        this.changeDraft = false
        this.formDescription = ''
        this.formSettings = {
          title: '',
          id: '',
          version: 1,
          attachment: [],
          public_key: '',
          state: 'open',
          phase: 'draft',
        }
        this.dialogDraftSettings = false
      },

      openDialog () {
        this.agreecheckbox = false
        this.dialogDelete = true
      },

      async deleteform () {
        // Run code from mixin to delete form's folder completely from ocpu and from bend
        // console.log(this.orgid)
        // console.log(this.prjid)
        // console.log(this.formid)
        await this.deleteOdkForm()
        this.dialogDelete = false
        this.dialogDraftSettings = false
        // this.$router.push({ name: 'Draft Forms' })
      },

      getSubmissions (ix) {
        this.loadDialog = true
        this.loadText = 'Checking server'
        // console.log(ix)
        const org = this.$store.state.bend.organization
        const prop = {
          orgid: org.orgid,
          prjid: org.projects[ix.pindex].prjid,
          formid: org.projects[ix.pindex].forms[ix.findex].odksettings.id,
        }
        const sclient = org.projects[ix.pindex].forms[ix.findex].submissions
        // console.log(prop)
        // Get number of submissions
        return this.$store
          .dispatch('ocpu/getSubmissions', prop)
          .then(sserver => {
            // console.log(sserver + ' ' + sclient)
            if (sserver > sclient) {
              // if data in server is bigger than in client...
              this.loadText = 'New data is available, starting to download'
              prop.sserver = sserver
              prop.projectid = org.projects[ix.pindex].forms[ix.findex].id
              prop.ix = ix
              // Run udactable function to download and save latest submission tables
              return this.$store
                .dispatch('ocpu/odataSubmissions', prop)
                .then(() => {
                  // Hide loading dialog
                  this.loadDialog = false
                  // this.$store.dispatch({
                  //   type: 'ocpu/runOcpuCode',
                  //   pkey: pkey,
                  // })
                })
            } else {
              // Hide loading dialog
              this.loadDialog = false
            }
          })
      },

      async prepDataExport () {
        this.loadingdata = true
        this.dDatasets = JSON.parse(JSON.stringify(this.dDatasetTemplate))
        // console.log(this.dDatasets)
        this.dialogExport = true
        await this.getdDataset('dDataset')
        // this.loadingdata = false
      },
      // Update this.dDatasets value (selected) to true according to packs array list
      // async getdDataset () {
      //   // Wait while we get data from ocpu
      //   await this.dBlockGet('dDataset')

      //   // selected datasets as array
      //   const selectedDset = this.$store.state.main.dsb.dDataset.filter(x => x.selected === true).map(x => x.pack)

      //   // Update this.dDatasets
      //   // this.dDatasets.map(dSet => {
      //   //   if (selectedDset.includes(dSet.pack)) {
      //   //     dSet.selected = true

      //   //     // get Syncronization data for each selected dataset
      //   //     const dsname = dSet.title
      //   //     // console.log(dsname)
      //   //     this.getdsSyncData(dsname.toLowerCase())
      //   //   }
      //   // })
      //   // this.loadingdata = false

      //   return selectedDset
      // },

      // Called from formActions
      publishDraft (prop) {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // console.log(prop)
        // Get ix from utilities mixin
        const ix = this.getIx(prop)
        const data = {
          orgid: this.$store.state.bend.organization.orgid,
          prjid: prop.project.prjid,
          formid: prop.form.odksettings.id,
        }
        // console.log(data)
        this.$httpOcpu
          .post(`/${ocpuPack}/R/ocpublishdraft/json`, data, {
          }).then((res) => {
            // console.log(res)
            // get enketoId from ocgetformdetail()
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocgetformdetail/json`, data, {
              }).then((res) => {
                // console.log(res)
                // change form settings to active and add enketoId
                this.$store.dispatch('bend/odkFormPhase', {
                  ix: ix,
                  formjsonid: prop.form.id,
                  phase: 'active',
                  enketoid: res.data.data.enketoId,
                })
              })
          })
          // .catch(error => {
        // console.log(error)
        // return reject(error.message)
          // })
      },

      async abandonDraft (prop) {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // Get ix from utilities mixin
        const ix = this.getIx(prop)
        // console.log(this.selectedForm)
        const data = {
          orgid: this.$store.state.bend.organization.orgid,
          prjid: prop.project.prjid,
          formid: prop.form.odksettings.id,
          type: 'draft',
        }
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocdeleteform/json`, data)
            .then(
              response => {
                // console.log(response)
                if (response.status === 201) {
                  //   resolve(response.data[0])
                  this.$store.dispatch('bend/odkFormPhase', {
                    ix: ix,
                    formjsonid: prop.form.id,
                    phase: 'draft',
                  })
                  this.dialogRemove = false
                  // this.formButtons(prop.form)
                }
              })
        })
      },

      async showTestCode (prop) {
        this.af = prop.form
        this.ap = prop.project
        // const p = prop.project
        // const f = prop.form
        const data = {
          orgid: this.$store.state.bend.organization.orgid,
          prjid: this.ap.prjid,
          formid: this.af.odksettings.id,
        }
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        this.$httpOcpu
          .post(`/${ocpuPack}/R/getdfdetails/json`, data, {
          }).then((res) => {
            this.dialogQR = true
            const server = this.$store.state.main.ap.odk_server
            // console.log(res.data[0])
            const token = res.data[0]
            this.qrCodeDisplayName = `${this.af.odksettings.id}-draft`
            // https://docs.getodk.org/collect-import-export/#list-of-keys-for-all-settings
            // server_url: `https://central.udaconsulting.com/v1/test/${token}/projects/${this.ap.pid}/forms/${this.af.odksettings.id}/draft`,
            const mysettings = {
              general: {
                server_url: `${server}/v1/test/${token}/projects/${this.ap.pid}/forms/${this.af.odksettings.id}/draft`,
                autosend: 'wifi_and_cellular',
              },
              admin: {
                send_finalized: true,
              },
            }

            var pako = require('pako')
            // encode to Uint8Array
            var Uint8Array = new TextEncoder('utf-8').encode(JSON.stringify(mysettings))
            // compress
            var bS = pako.deflate(Uint8Array, { to: 'string' })
            // encode base 64
            var bs64 = btoa(bS)
            this.qrCode = bs64
          })
      },

      async formPreview (prop) {
        // console.log(prop)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const eid = prop.form.odksettings.enketoid
        // console.log(Object.keys(prop.form.odksettings))
        if (eid === undefined || Object.keys(eid).length === 0) {
          // console.log('if da')
          const data = {
            orgid: this.$store.state.bend.organization.orgid,
            prjid: prop.project.prjid,
            formid: prop.form.odksettings.id,
          }
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocgetformdetail/json`, data, {
            }).then((res) => {
              // console.log(res)
              // console.log(res.data.data.enketoId)
              // console.log(prop.project.odk_server)
              const server = prop.project.odk_server
              const enketoid = res.data.data.enketoId
              if (Object.entries(enketoid).length === 0) {
                //
                this.snackBar = {
                  type: 'warning',
                  mode: 'multi-line',
                  timeout: 12000,
                  title: `${this.$t('common.warning')}`,
                  text: `${this.$t('project-list.preview-note')}`,
                  visible: true,
                }
                this.uploadDisabled = true
              } else {
                // open preview link
                window.open(`${server}/-/preview/${enketoid[0]}`, '_blank')
              }
              // console.log('if')
              // Get ix from utilities mixin
              const ix = this.getIx(prop)

              // write encetoId into formsettings
              this.$store.dispatch('bend/odkFormPhase', {
                ix: ix,
                formjsonid: prop.form.id,
                phase: 'deployed',
                enketoid: enketoid,
                // attachment: res.data.attachment,
              })
            })
        } else {
          const server = prop.project.odk_server
          const enketoid = prop.form.odksettings.enketoid
          if (server === undefined) {
            //
            this.snackBar = {
              type: 'warning',
              mode: 'multi-line',
              timeout: 12000,
              title: `${this.$t('common.warning')}`,
              text: `${this.$t('project-list.preview-note')}`,
              visible: true,
            }
            this.uploadDisabled = true
          } else {
            // open preview link
            window.open(`${server}/-/preview/${enketoid[0]}`, '_blank')
          }
        }
      },

      saveQRCode () {
        // console.log(this.af.odksettings.id)
        const img = this.$refs.qr.$el.toDataURL()
        var link = document.createElement('a')
        link.download = `QR_CodeTest-${this.af.odksettings.id}.png`
        link.href = img
        // console.log(link)
        document.body.appendChild(link)
        link.click()
        this.dialogQR = false
      },

      getDSubmission (prop) {
        const p = prop.project
        const f = prop.form
        const data = {
          orgid: this.$store.state.bend.organization.orgid,
          prjid: p.prjid,
          formid: f.odksettings.id,
        }
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // console.log(data)
        this.$httpOcpu
          .post(`/${ocpuPack}/R/getdraftdata/json`, data, {
          }).then((res) => {
            // console.log(res.headers['x-ocpu-session'])
            const ocpuUrl = this.$store.state.ocpu.ocpuUrl
            const link = `${ocpuUrl}/tmp/${res.headers['x-ocpu-session']}/files/${res.data[0]}`
            // console.log(link)
            // console.log(dDataset)
            // console.log(fileName)
            axios({
              url: link,
              method: 'GET',
              responseType: 'blob',
            }).then(response => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
              const fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', res.data[0])
              document.body.appendChild(fileLink)
              fileLink.click()
              window.URL.revokeObjectURL(fileURL)
            })
            // Run if response is successfull
          })
      },

      // Draft form methods
      xlsFormSelected () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        // console.log(main.orgid)
        if (this.xlsform !== undefined) {
          if (Object.keys(this.xlsform).length === 0) {
            // const fpath = `${this.orgid}/${this.prjid}/${this.formid}`
            // console.log(fpath)

            this.xlsfuploading = true
            this.formLang = []
            this.defLang = []
            const formData = new FormData()
            formData.append('orgid', JSON.stringify(main.orgid))
            formData.append('prjid', JSON.stringify(main.ap.prjid))
            formData.append('formid', JSON.stringify(main.afid))
            formData.append('xlsform', this.xlsform)
            // console.log(formData)
            this.$httpOcpu
              // .post(`/${ocpuPack}/R/ocxlsform/json`, formData).then((res) => {
              .post(`/${ocpuPack}/R/ocxlsform/json`, formData).then((res) => {
                this.formLang = res.data
                this.defLang = res.data[0]
                this.xlsfuploading = false
                // console.log(res)
              })
          }
        }
      },

      getxlsformtemplate () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const filename = { filename: 'XLSFormTemplate.xlsx' }
        // console.log(filename)
        this.$httpOcpu.post(`/${ocpuPack}/R/getufile/json`, filename)
          .then(response => {
            // console.log(response)
            const link = `${response.headers.location}/files/${response.data[0]}`
            const fileName = response.data[0]
            // console.log(link)
            // console.log(fileName)
            axios({
              url: link,
              method: 'GET',
              responseType: 'blob',
            }).then(response => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
              const fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', fileName)
              document.body.appendChild(fileLink)
              fileLink.click()
              window.URL.revokeObjectURL(fileURL)
            })
          })
      },

      uploadForm () {
        this.loading = true
        const main = this.$store.state.main
        const prop = {
          ocpu: {
            fsettings: JSON.stringify(main.af.odksettings),
            orgid: main.orgid,
            prjid: main.ap.prjid,
            formid: main.afid,
            deflang: this.defLang.code,
          },
          ix: this.getIx({ project: main.ap, form: main.af }),
          caller: 'pl',
        }
        // console.log(prop)
        this.uploadXLSForm(prop)
      },

      attachExtension (attachment) {
        const ext = attachment.split('.').pop()
        // console.log(ext)
        return `.${ext}`
      },

      uploadAttachment () {
        // console.log('uploading')
        this.loading = true
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        const ix = this.getIx({ project: main.ap, form: main.af })
        const formData = new FormData()
        formData.append('orgid', JSON.stringify(main.orgid))
        formData.append('prjid', JSON.stringify(main.ap.prjid))
        formData.append('formid', JSON.stringify(main.afid))
        formData.append('attachment', this.attached)
        // console.log(formData)
        this.$httpOcpu
          .post(`/${ocpuPack}/R/ocuploadfattach/json`, formData).then((res) => {
            // console.log(res.data)
            // if (res.data === 'Success') {
            // mutate settings
            // change bend
            this.$store.dispatch('bend/odkFormPhase', {
              ix: ix,
              formjsonid: main.af.id,
              phase: 'deployed',
              attachment: res.data.attachs,
              enketoid: res.data.fdetails.data.enketoId,
            }).then(() => {
              // console.log(ix)
              this.attached = null
            })
            this.loading = false
            this.dialogUploadForm = false
            // }
          })
      },

      deleteAttachment (attachment) {
        this.loading = true
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        const ix = this.getIx({ project: main.ap, form: main.af })
        const data = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
          attachment: attachment,
        }
        // console.log(attachment)
        this.$httpOcpu
          .post(`/${ocpuPack}/R/ocdeletefattach/json`, data).then((res) => {
            // console.log(res.data)
            // if (res.data === 'Success') {
            // mutate settings
            // change bend
            this.$store.dispatch('bend/odkFormPhase', {
              ix: ix,
              formjsonid: main.af.id,
              phase: 'deployed',
              attachment: res.data,
            }).then(() => {
              // console.log(ix)
              this.attached = null
            })
            this.loading = false
            this.dialogUploadForm = false
            // }
          })
      },

      // Add new project into the list
      async addProject () {
        this.isProject = await this.postProject(this.projectName)
        await this.getProjects()
        // console.log(this.isProject)
        this.dialogAddProject = false
      },

      async saveNewForm () {
        // Saves new form or updates existing
        if (!this.changeDraft) {
          // console.log(this.selectedProject)
          await this.postOdkForm()
          this.dialogAddForm = false
          await this.getProjects()
          this.projects = this.usersProjects()
          // this.getProjects()
        } else {
          // console.log(this.formSettings)
          // console.log(this.selectedForm)
          // console.log(this.selectedProject)
          // Call patchOdkForm() from forms.js to patch form bend
          const org = this.$store.state.bend.organization
          const patchBody = {
            data: {
              type: 'node--form',
              id: this.selectedForm.id,
              attributes: {
                field_form_settings: JSON.stringify(this.formSettings),
              },
            },
          }
          const bendform = await this.patchOdkForm({ orgid: org.orgid, formjsonid: this.selectedForm.id, patchBody: patchBody })
          // If patchOdkForm() is successfull call patchOcpuForm() from forms.js
          // TODO change it. the function ocformchange() is empty in ocpu
          if (bendform) {
            const ocpuform = await this.patchOcpuForm()
            if (ocpuform) {
              // console.log('OK')
              this.changeDraft = false
              this.dialogAddForm = false
            } else {
              // console.log('ocpu Error')
            }
          } else {
            // console.log('bend Error')
          }
        }
      },

      editName () {
        this.formNameEdit = !this.formNameEdit
        if (this.formNameEdit) {
          this.formEditIcon = 'mdi-restore'
        } else {
          this.formSettings.id = this.formSettings.title.replace(/\W/g, '_').toLowerCase()
          this.formEditIcon = 'mdi-pencil'
        }
      },

      // Auto name the form id based on the form Title
      formAutoName () {
        // Forms id's are fixed. Use following code when we allow multi forms.
        if (!this.formNameEdit && !this.changeDraft) {
          this.formSettings.id = this.formSettings.title.replace(/\W/g, '_').toLowerCase()
        }
      },

      // No space when manually changing instance name
      noSpace () {
        this.formSettings.id = this.formSettings.id.replace(/\W/g, '_').toLowerCase()
      },
    },

  }
</script>

<style lang="sass">
  .project.active
    border-left: 4px solid green
  .project.deployed
    border-left: 4px solid orange
  .project.draft
    border-left: 4px solid lightgrey

  .v-chip.active
    background: green !important
  .v-chip.deployed
    background: orange !important
  .v-chip.draft
    background: grey !important
</style>
