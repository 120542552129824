/**
  ODK Forms related bend and ocpu functions
  - postOdkForm ()
  - deleteOdkForm ()
  - patchOdkForm ()
  - patchOcpuForm ()
  - uploadXLSForm ()
  NOTE: Get Forms is included into getProjects function (projects.js)
 */

export default {
  data: () => ({

    // formSettings: {
    //   title: '',
    //   id: '',
    //   version: 1,
    //   attachment: [],
    //   public_key: '',
    //   state: 'open',
    //   phase: 'draft',
    // },

  }),

  methods: {
    /**
     * NOTE: Get Forms is included into getProjects function
     *
     * 1. Post new ODK Form
     *  1.2 Commit new organization's data into vuex (bend/setOrganization)
     *  1.3 Set call.organization state to true
     * 2. Patch selected project to attach forms
     *  2.2 Commit defauld Role into vuex (bend/setDefaultRole)
     * 3. Run ocfolderform() function in opencpu to add a form folder in OCPU Server
     */
    async postOdkForm () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      // new form object
      let newfid = ''
      const org = this.$store.state.bend.organization

      // STEP 2. Function to attach new form into it's project
      const updateProject = (newform) => {
        const projectId = this.selectedProject.id
        const projectIndex = org.projects.map(function (e) { return e.id }).indexOf(this.selectedProject.id)
        const existingform = ('forms' in this.selectedProject) ? this.selectedProject.forms : false
        const nf = {
          type: 'node--form',
          id: `${newfid}`,
        }
        const body = {}
        body.data = []
        // if Project has forms we should preserve while adding a new form
        if (existingform) {
          // console.log('existingform if')
          // loop existing project and prepare post body
          existingform.forEach((item, i) => {
            body.data[i] = {}
            body.data[i].type = 'node--form'
            body.data[i].id = item.id
          })
          // include new project to the beginning
          body.data.unshift(nf)
        } else {
          // if organization has no project add only the new project
          body.data = [nf]
        }
        // console.log(body)
        // console.log(projectId)
        // console.log(org.orgid)

        // Patch Project and add forms
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/nodepatch/json`, {
              path: `jsonapi/node/project/${projectId}/relationships/field_odkform`,
              patchBody: body,
              orgid: org.orgid,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                // prepare Project's forms: concat new and existing if there were any forms
                // console.log(existingform)
                const updatedforms = !existingform ? newform : newform.concat(existingform)
                // STEP 3.2 Save updated form to project
                this.$store.commit('bend/setProjectForms', { forms: updatedforms, pindex: projectIndex })
                // this.projects = org.projects
              } else {
                // return resolve(false)
              }
              // console.log(response)
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      // STEP 3. Run ocfolderform() function in opencpu to add a form folder in OCPU Server
      const addformfolder = (folder) => {
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocfolderform/json`, {
              orgid: org.orgid,
              prjid: this.selectedProject.prjid,
              formid: this.formSettings.id,
              formtitle: this.formSettings.title,
            })
            .then(response => {
              // console.log(response)
              if (response.data[0] === true) {
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      }

      // STEP 1. Post new ODK Form
      const body = {
        data: {
          type: 'node--form',
          attributes: {
            title: `${org.title + ' - ' + this.selectedProject.prjid + ' (' + this.formSettings.id + ')'}`,
            // TODO Delete this separete form_id later
            // field_form_id: `${this.formSettings.id}`,
            body: `${this.formDescription}`,
            field_form_settings: `${JSON.stringify(this.formSettings)}`,
          },
        },
      }
      return new Promise((resolve, reject) => {
        this.$httpBend
          .post('jsonapi/node/form', body, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(async response => {
            // console.log(response)
            if (response.status === 201) {
              const newform = []
              const obj = {}
              const data = response.data.data
              newfid = data.id
              obj.id = data.id
              obj.title = this.formSettings.title
              obj.body = data.attributes.body.value
              obj.formid = this.formSettings.id
              obj.odksettings = this.formSettings
              obj.odkquestions = []
              obj.odkmodels = []
              obj.odklangs = [{ code: 'en', label: 'English', default: true, done: null }]
              newform.push(obj)

              // STEP 2. Post default Role using active user and Organization's id
              updateProject(newform)

              // STEP 3. Add a form folder in OCPU Server
              addformfolder()

              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            return reject(error.message)
          })
      })
    },

    /**
     * NOTE: Get Forms is included into getProjects function
     *
     * 1. Delete form node from project node in bend
     *  1.1 Prepare left forms of the project
     *  1.2 Patch Project and re-write only left forms
     * 2. Delete Form node from bend
     * 3. Commit vuex to set left forms
     * 4. Run ocdeleteform() function in ocpu with type='final' to delete form folder completely in OCPU Server
     */
    async deleteOdkForm () {
      const org = this.$store.state.bend.organization
      const main = this.$store.state.main
      // console.log(main)
      const projectId = main.ap.id
      const formjsonid = main.af.id
      const leftForms = main.ap.forms.filter(f => f.id !== formjsonid)
      const ocpuPack = this.$store.state.ocpu.ocpuPack

      // STEP 2. Delete Form node from bend (Run OCPU Code to delete node)
      const deleteform = () => {
        // console.log(org.orgid)
        return new Promise((resolve, reject) => {
          this.$httpOcpu.post(`/${ocpuPack}/R/nodedelete/json`, {
            path: `/jsonapi/node/form/${formjsonid}`,
            orgid: org.orgid,
          })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        })
      }

      // STEP 3. Run ocdeleteform(... type='final') function in opencpu to delete form folder completely in OCPU Server
      const deleteformfolder = () => {
        // console.log(org.orgid + ' ' + main.ap.prjid + ' ' + main.afid)
        return new Promise((resolve, reject) => {
          this.$httpOcpu.post(`/${ocpuPack}/R/ocdeleteform/json`, {
            orgid: org.orgid,
            prjid: main.ap.prjid,
            formid: main.afid,
            type: 'final',
          })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        })
      }

      // STEP 1. Delete form node from project node in bend
      // STEP 1.1. Prepare left forms of the project
      // Function to remover current form from it's project
      // const formjsonid = org.projects[this.ix.pindex].forms[this.ix.findex].id
      // console.log(formjsonid)
      // console.log(leftForms)
      // console.log(leftForms.length)

      const patchBody = {}
      patchBody.data = []
      // if Project has any other forms we should keep them
      if (leftForms.length > 0) {
        // loop existing project and prepare post patchBody
        leftForms.forEach((item, i) => {
          patchBody.data[i] = {}
          patchBody.data[i].type = 'node--form'
          patchBody.data[i].id = item.id
        })
      }

      // console.log(JSON.stringify(patchBody))
      // console.log(patchBody)
      // console.log(projectId)

      // STEP 1.2 Patch Project and re-write only left forms (Run OCPU Code to patch node)
      // Should use JSON.stringify() otherwise there is error when sending empty patchBody.data = []
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `jsonapi/node/project/${projectId}/relationships/field_odkform`,
            patchBody: JSON.stringify(patchBody),
            orgid: org.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // STEP 2. Delete Form node from bend
              deleteform()

              // STEP 3. Commit vuex to set left forms
              this.$store.commit('bend/setProjectForms', { forms: leftForms, pindex: main.ix.pindex })

              // STEP 4. Delete form folder completely in OCPU Server
              deleteformfolder()
              this.projects = org.projects
              resolve(response)
            } else {
              // return resolve(false)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Patch OdkForm from bend via ocpu
    async patchOdkForm (prop) {
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/form/${prop.formjsonid}`,
            patchBody: prop.patchBody,
            orgid: prop.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
            // return reject(error.message)
          })
      })
    },

    // TODO change it. the function ocformchange() is empty in ocpu
    async patchOcpuForm () {
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      const org = this.$store.state.bend.organization
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/ocformchange/json`, {
            orgid: org.orgid,
            prjid: this.selectedProject.prjid,
            formid: this.formSettings.id,
            formtitle: this.formSettings.title,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
            // return reject(error.message)
          })
      })
    },

    // Upload XLSFrom into ocpu
    uploadXLSForm (prop) {
      // console.log(JSON.stringify(this.form.odksettings))
      this.loading = true
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      // console.log(prop)
      return new Promise((resolve, reject) => {
      this.$httpOcpu
        .post(`/${ocpuPack}/R/ocuploadxlsf/json`, prop.ocpu, {
        })
        .then((res) => {
          // Run if response is successfull
          // console.log(res)
          if (res.data.status.category[0] === 'Success') {
            // if response is successfull
            this.$store.dispatch('bend/odkFormPhase', {
              ix: prop.ix,
              formjsonid: this.$store.state.main.af.id,
              phase: 'deployed',
              attachment: res.data.attachment,
              enketoid: res.data.data.enketoId,
            }).then(() => {
              // if this was called from form designer navigate to project list
              if (prop.caller === 'fd') {
                this.$router.push({ name: 'Project List' })
              }
            })
            this.loading = false
            this.dialogUploadForm = false
          } else if (res.data.status.category[0] === 'Client error') {
            // console.log(res.data.data.details)
            const errorDetail = 'error' in res.data.data.details ? res.data.data.details.error[0] : 'No details'
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              timeout: 18000,
              title: `${this.$t('common.error')}`,
              text: `${res.data.status.message[0]}. ${res.data.data.message[0]} <br/> ${errorDetail}`,
              visible: true,
            }
            this.loading = false
            this.uploadDisabled = true
            return false
          } else {
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              timeout: 18000,
              title: res.data.status.category[0],
              text: `${res.data.status.message[0]}. ${res.data.data.message[0]} ${res.data.data.details.error[0]} `,
              visible: true,
            }
            this.loading = false
            this.uploadDisabled = true
            return false
          }
        })
        .catch(error => {
          // console.log(error)
          this.loading = false
          this.snackBar = {
            type: 'error',
            mode: 'multi-line',
            timeout: 18000,
            // title: error.response.statusText,
            // text: error.response.data,
            visible: true,
          }
          return reject(error.message)
        })
      })
    },

  },
}
