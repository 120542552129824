/**
 * Get project roles are included in get-projects.js?
 */
export default {
  methods: {
    /**
     * Add a new role to Project. Logic:
     * 1. Prepare data body for post request
     * 1.1 Add a new role node
     * 2. Patch Project to add new role
     * 3. Commit vuex to add newly added role to the project
     */
    async postProjectrole (props) {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      // Prepare variables
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      const org = this.$store.state.bend.organization

      // // variable to store newly created project's json id
      const existingRoles = this.ap.roles

      // STEP 2 Patch Project to add new role
      const patchProject = async (id) => {
        // console.log('patchOrganization')

        // STEP 2.1 Prepare data to send
        const nr = {
          type: 'node--roles',
          id: `${id}`,
        }
        const body = {}
        body.data = []

        // if Project has forms we should preserve while adding a new form
        // loop existing project and prepare post body
        existingRoles.forEach((item, i) => {
          body.data[i] = {}
          body.data[i].type = 'node--roles'
          body.data[i].id = item.roleid
        })
        // include new project to the beginning
        body.data.push(nr)
        // console.log(body)

        // STEP 2.2 Patch Project and add new role (Run OCPU Code to patch node)
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/nodepatch/json`, {
              path: `jsonapi/node/project/${this.ap.id}/relationships/field_roles`,
              patchBody: body,
              orgid: org.orgid,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                // show information about success
                this.snackBar = {
                  type: 'success',
                  mode: 'multi-line',
                  text: `New Web user - '${this.selectedStaff.name}' is successfully added`,
                  visible: true,
                }

                // close dialogue
                this.selectedStaff = ''
                this.selectedRole = ''
                this.dialogRoles = false
                return resolve(true)
                // resolve(response)
              } else {
                // return resolve(false)
              }
            })
            .catch(error => {
              reject(error)
            })
        })
      }

      // STEP 3. Commit vuex to add newly added role to the project
      const newRoles = (prop) => {
        // console.log(prop)
        const user = this.$store.state.bend.organization.allStaff.filter(u => u.uuid === props.uuid)
        // console.log(user)
        const nRole = [{
          access: '',
          changed: user[0].changed,
          created: user[0].created,
          id: user[0].uuid,
          name: user[0].name,
          role: props.role,
          roleid: prop.id,
        }]
        // Merge Roles using ES5 Array.concat: same could be done using ES6 [...nRole, ...existingRoles]
        const allRoles = nRole.concat(existingRoles)
        this.$store.commit('main/setProjectRoles', { roles: allRoles })
      }

      // STEP 1. Post New Role based on recieved data (props)
      // Prepare data to be POST
      const data = {
        data: {
          type: 'node--roles',
          attributes: { field_project_role: props.role },
          relationships: {
            field_organization: { data: [{ type: 'node--organization', id: `${org.id}` }] },
            field_user: { data: [{ type: 'user--user', id: props.uuid }] },
          },
        },
      }
      // console.log(data)
      return new Promise((resolve, reject) => {
        this.$httpBend
          .post('jsonapi/node/roles', data, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              const id = response.data.data.id

              // Call Step 2
              patchProject(id)

              // Call Step 3
              newRoles(response.data.data)

              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error.message)
            return reject(error.message)
          })
      })
    },

    /**
     * 1. Delete project node from Organization node in bend
     *  1.1 Prepare left projects of the Organization
     *  1.2 Patch Organization and re-write only left projects
     * 2. Delete Role node from bend (Run OCPU Code to delete node)
     * 3. Commit vuex to set left projects
     * 4. Run ocdeleteproject() function in ocpu to delete project folder completely in OCPU Server
     */
    async deleteRole (rolejsonid) {
      // console.log(rolejsonid)
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      const org = this.$store.state.bend.organization

      // STEP 2. Delete Role node from bend (Run OCPU Code to delete node)
      const deleteprolenode = () => {
        // console.log(org.orgid)
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/nodedelete/json`, {
              path: `/jsonapi/node/roles/${rolejsonid}`,
              orgid: org.orgid,
            })
            .then(response => {
              // console.log(response)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }

      // STEP 1. Patch project node in bend to delete the role relation
      // STEP 1.1. Prepare left roles of the project
      // Function to remover current role from it's project
      const leftRoles = this.ap.roles.filter(r => r.roleid !== rolejsonid)
      // console.log(leftRoles)
      const body = {}
      body.data = []
      // if Project has any other roles we should keep them
      if (leftRoles.length > 0) {
        // loop existing organization and prepare post body
        leftRoles.forEach((item, i) => {
          body.data[i] = {}
          body.data[i].type = 'node--roles'
          body.data[i].id = item.roleid
        })
      }
      // console.log(body)
      // console.log(this.ap.id)

      // STEP 1.2 Patch Project and re-write only left roles (Run OCPU Code to patch node)
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `jsonapi/node/project/${this.ap.id}/relationships/field_roles`,
            patchBody: body,
            orgid: org.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // STEP 2. Delete roles node from bend
              deleteprolenode()

              // STEP 3. Commit vuex to set left projects
              this.$store.commit('main/setProjectRoles', { roles: leftRoles })
              // this.$store.commit('bend/setProjectRoles', { ix: this.$store.state.main.ix, roles: leftRoles })
              resolve(response)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
